@use "base" as *;
@use "components";
// $font-color: $dark-700;

:root {
  --shadow-color: 152deg 36% 56%;
  --shadow-elevation-low: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
    0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.34),
    1px 2px 2.5px -2.5px hsl(var(--shadow-color) / 0.34);
  --shadow-elevation-medium: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
    0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
    2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
    5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);
  --shadow-elevation-high: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
    1.5px 2.9px 3.7px -0.4px hsl(var(--shadow-color) / 0.34),
    2.7px 5.4px 6.8px -0.7px hsl(var(--shadow-color) / 0.34),
    4.5px 8.9px 11.2px -1.1px hsl(var(--shadow-color) / 0.34),
    7.1px 14.3px 18px -1.4px hsl(var(--shadow-color) / 0.34),
    11.2px 22.3px 28.1px -1.8px hsl(var(--shadow-color) / 0.34),
    17px 33.9px 42.7px -2.1px hsl(var(--shadow-color) / 0.34),
    25px 50px 62.9px -2.5px hsl(var(--shadow-color) / 0.34);

  --font-fallback: BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-sans;
  --font-body: InterSubset, var(--font-fallback);
  --font-heading: InterSubset, var(--font-fallback);
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  --accent-primary: $accent-color;
}

::selection {
  color: black;
  // background: hsla(0, 0%, 100%, 25%);
  // background: #45E595;
  background: var(--accent-primary)
}



body {
  font-family: var(--font-body);
  // font-family: var(--font-body);
  background-color: $background-primary;
  color: $text-primary;
  accent-color: var(--accent-primary);

  // &:after {
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   background-image: url("./../assets/svg/noise.svg");
  //   top: 0;
  //   pointer-events: none;
  //   background-size: 10vw;
  // }
}

// a:link {
//   color: $cyan-100;
// }

// a:visited {
//   color: $yellow-100;
// }

// .button {
//   position: relative;
//   background-color: transparent;
//   border: 0.1em solid $yellow-100;
//   font-size: 1rem;
//   min-width: 2em;
//   padding: 0.5em 1.5em;
// }

// .button:before {
//   position: absolute;
//   left: -0.1em;
//   top: -0.1em;
//   content: "";
//   border-top: 1em solid $yellow-100;
//   border-right: 1em solid transparent;
// }

// .button:after {
//   position: absolute;
//   right: -0.1em;
//   bottom: -0.2em;
//   content: "";
//   border-bottom: 1em solid $yellow-100;
//   border-left: 1em solid $red-100;
// }

// html {
//   font-family: sans-serif;
//   background-color: $dark-700;
//   color: #ffffff;
//   padding: 0;
//   margin: 0;
// }

// body {
//   padding: 0;
//   margin: 0;
// }

// h1 {
//   text-shadow: var(--shadow-elevation-medium);
//   background-color: #15162e;
//   border-radius: 0.2em;
//   padding: 1em;
//   color: #ffffff;
//   letter-spacing: 0.1em;
//   // border: 1px solid transparentize($dark-700, .9);
// }

// .page-layout {
//   display: flex;
//   flex-direction: column;
// }

// .content {
//   margin: 0px auto;
//   width: 100%;
//   max-width: 1600px;
//   min-height: 100vh;
// }

// .section {
//   max-width: 900px;
// }

// .nav-bar {
//   top: 0;
//   position: sticky;
//   width: 100%;
//   height: 5em;
//   background-color: red;
// }

// .hero {
//   font-size: 2em;
//   padding: 3em;
//   color: #00abff;
//   //   background-image: linear-gradient(
//   //   45deg,
//   //   hsl(55deg 100% 50%) 0%,
//   //   hsl(63deg 87% 55%) 11%,
//   //   hsl(73deg 90% 61%) 22%,
//   //   hsl(85deg 94% 67%) 33%,
//   //   hsl(101deg 96% 72%) 44%,
//   //   hsl(121deg 99% 76%) 56%,
//   //   hsl(140deg 100% 72%) 67%,
//   //   hsl(154deg 100% 66%) 78%,
//   //   hsl(163deg 100% 60%) 89%,
//   //   hsl(171deg 100% 50%) 100%
//   // );
// }

// .side-lines {
//   &:before {
//     content: "";
//     position: absolute;
//     height: 100%;
//     transform: translateX(-0.3em);
//     width: 0.3em;
//     height: 100%;
//     background-image: linear-gradient(90deg, black 50%, transparent 50%);
//     background-size: 0.1em;
//   }
// }

// .color-bar {
//   position: relative;

//   &:after {
//     content: "";
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 0.1em;
//     background-image: linear-gradient(
//       90deg,
//       $cyan-100 25%,
//       $yellow-100 25%,
//       $yellow-100 50%,
//       $orange-100 50%,
//       $orange-100 75%,
//       $red-100 75%
//     );
//     background-size: 400%;
//     transition: all 0.5s;
//     background-size: 100%;
//     background-position-x: calc(100% / 3 * 3);
//   }
// }

// .pop-card {
//   background-color: $red-100;

//   width: 15em;
//   min-height: 15em;
//   position: relative;

//   &:before {
//     content: "";
//     right: 1em;
//     top: 1em;
//     position: absolute;
//     background-color: #15162e;
//     border-radius: 2em;
//     width: 2em;
//     height: calc(100% - 2em);
//   }
// }

// .sand-pit {
//   background-color: #dedede;
//   color: #007dff;
//   min-height: 100vh;
//   padding: 2em;
//   font-size: 2rem;

//   .button {
//     --positionX: 5px;
//     --positionXOpposite: -5px;
//     --positionY: 5px;
//     --positionYOpposite: -5px;
//     --angle: 145deg;
//     --blur: 12px;
//     --textColor: #001f3f;
//     --textColorOpposite: #dedede;
//     --baseColor: #dedede;
//     --darkColor: #595959;
//     --lightColor: #ffffff;
//     --firstGradientColor: #c8c8c8;
//     --secondGradientColor: #eeeeee;
//     --size: 152px;
//     --radius: 16px;
//     border-radius: 6px;

//     box-shadow: var(--positionX) var(--positionY) var(--blur) var(--darkColor),
//       var(--positionXOpposite) var(--positionYOpposite) var(--blur)
//         var(--lightColor);

//     outline-offset: 0;
//     outline: 0 solid #007dff;
//     transition: all 0.25s ease-in-out;

//     --textColorOpposite: #007dff;
//     --baseColor: #007dff;
//     --darkColor: #003266;
//     --lightColor: #00c8ff;
//     --firstGradientColor: #007dff;
//     --secondGradientColor: #007dff;

//     outline: 5px solid #007dff;
//     box-shadow: var(--positionX) var(--positionY) var(--blur) var(--darkColor),
//       var(--positionXOpposite) var(--positionYOpposite) var(--blur)
//         var(--lightColor);

//     &:hover {
//       outline-offset: 0.1em;
//       outline: 5px solid #007dff;
//       box-shadow: var(--positionX) var(--positionY) var(--blur) var(--darkColor),
//         var(--positionXOpposite) var(--positionYOpposite) var(--blur)
//           var(--lightColor);
//     }
//   }

//   * {
//     border: none;
//     // box-shadow: 5px 5px 10px #dcdcdc, -5px -5px 10px #ffffff;
//   }

//   form {
//     padding: 1em;
//   }
// }

// .halftone {
//   --mask: linear-gradient(rgb(0 0 0), rgb(0 0 0 / 0.5));
//   --dotsColor: 255 255 255;
//   --bg: radial-gradient(
//     circle at center,
//     rgb(var(--dotsColor)) 3%,
//     rgb(var(--dotsColor) / 0.4) var(--dotRadius, 70%)
//   );
//   --bgSize: var(--size);
//   --cardBg: linear-gradient(135deg, hotpink, blue);

//   --bgSize: calc(var(--size) * 1.3);
//   --bgPosition: calc(var(--bgSize) / 2);
//   --stops: rgb(var(--dotsColor)) 3%,
//     rgb(var(--dotsColor) / 0.15) calc(var(--dotRadius, 53%) * 0.95);
//   --bg: radial-gradient(circle at center, var(--stops)),
//     radial-gradient(circle at center, var(--stops));

//   --dotRadius: 75%;
//   --blendMode: multiply;
//   --size: 0.7rem;
//   --contrast: 20;
//   --invert: 0;

//   position: relative;
//   background: rgb(0 0 0);
//   filter: contrast(20);
//   mix-blend-mode: var(--blendMode, multiply);
// }

// .halftone::before {
//   content: "";
//   position: absolute;
//   inset: 0;
//   background-image: var(--bg);
//   background-size: var(--bgSize, 0.75rem) var(--bgSize, 0.75rem);
//   background-repeat: round;
//   background-position: 0 0, var(--bgPosition) var(--bgPosition);
//   -webkit-mask-image: var(--mask);
//   mask-image: var(--mask);
//   mask-size: cover;
//   mask-position: center;
// }

.blob {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50px 0;
  pointer-events: none;
  opacity: .3;

  .f {
    // background-color: red;
    width: 100%;
    height: 100%;
  }
}
