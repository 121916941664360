@use "../base/vars" as vars;

// .hero-layout {
//   section {
//     max-width: 52rem;
//     margin: 0 auto;
//     padding: 5em 0;
//     font-size: 2rem;
//   }
// }

.page-layout {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.section {
  height: 100vh;
  flex: 0 0 100vh;
  padding: 1em;

  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.snap-start {
  scroll-snap-align: start;
}

.snap-center {
  scroll-snap-align: center;
}

.snap-end {
  scroll-snap-align: end;
}

.header {
  width: 100%;
  max-width: vars.$page-content-width;
  min-height: 5em;

  // color: hsla(0, 0%, 100%, 0.5);
  color: white;
  text-decoration: none;
  font-size: 1.8em;
  text-align: center;
  font-weight: 300;
  margin-bottom: 1em;
  // transition: all 0.5s cubic-bezier(0.38, 0.01, 0.23, 1.01);

  > * {
    color: white;
    text-decoration: none;
    font-size: 1.8em;
    text-align: center;
    font-weight: 300;
    // transition: all 0.5s cubic-bezier(0.38, 0.01, 0.23, 1.01);

    .expand {
      // transition: all 0.5s cubic-bezier(0.38, 0.01, 0.23, 1.01);
    }

    &:hover {
      .expand {
        font-weight: 500;
        color: white;
      }
    }
  }
}

.reader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20em;
  min-height: 20em;
  width: 100%;
  text-align: center;
  padding: 0 1.5em;
  // background: red;
  // pointer-events: none;
  // margin-top: -20em;
  // padding-top: calc(100vh - 20em);

  // &:before {
  //   content: "";
  //   display: block;
  //   position: relative;
  //   height: 20em;
  //   background-color: red;
  // }

  position: relative;
  .mark {
    content: "";
    position: absolute;
    pointer-events: none;
    // background-color: red;
    width: 100%;
    height: calc(50% + 50vh);
    bottom: 0;
  }

  p {
    opacity: 0.5;
  }

  a {
    color: white;
    // color: hsla(0, 0%, 100%, 0.5);
    // opacity: 0.5;
    text-decoration: none;
    font-size: 1.8em;
    max-width: vars.$page-content-width;
    transition: color 0.5s cubic-bezier(0.38, 0.01, 0.23, 1.01);

    @media (max-width: 30em) {
      font-size: 1.4em;
    }

    &:hover {
      // color: white;
    }
  }
}
