@use "../base/colors" as color;
@use "../base/vars" as vars;

.action-icons-card {
  // a card with full width icons
  // display: grid;
  // grid-template-rows: repeat(auto, 5em);
  // grid-template-columns: repeat(auto, 5em);
  display: flex;
  border: 1px solid;

  // &:not(:first-child) {
  //   border-left: 1px solid;
  // }

  @media (max-width: 30em) {
    flex-direction: column;
  }

  &__link-item {
    --item-size: calc(#{vars.$page-content-width} / 3);
    @media (max-width: 36rem) {
      --item-size: calc(#{vars.$mobile-page-content-width} / 3);
    }
    
    & {
      flex: 0 0 var(--item-size); // Wrap the declaration in `& {}` to maintain behavior
      width: var(--item-size);
      height: var(--item-size);
    }

    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    .vector-icon {
      width: 3em;
    }

    transition: background-color 0.5s cubic-bezier(0.38, 0.01, 0.23, 1.01);

    &:hover {
      background-color: hsla(0, 0%, 100%, 10%);
    }
  }
}

// .call-to-action {
//   max-width: 52rem;
//   width: 100%;
//   margin: 0 auto;
//   border-color: color.$yellow-100;
//   background: color.$dark-700;
//   border-width: 0.1em;
//   border-style: solid;
//   // border-radius: .3em;
//   color: color.$background-primary;
//   padding: 4em 2em 1em 2em;
//   display: flex;
//   gap: 2rem;
//   position: relative;
//   background: color.$yellow-100;

//   --mask: linear-gradient(180deg, transparent 60%, rgb(0 0 0 / 60%));

//   &:hover {
//     // --mask: linear-gradient(0deg, transparent 60%, rgb(0 0 0 / 60%));
//     &:after {
//       --offset: 1em;
//       mask-size: 100% 100%;
//       mask-position: bottom right;
//     }
//   }

//   &:after {
//     --offset: 0.6em;
//     --bg-dot-size: 0.099em;
//     --gap: calc(var(--bg-dot-size) * 4);
//     z-index: -1;
//     content: "";
//     width: 100%;
//     height: 100%;
//     top: var(--offset);
//     left: 0;
//     background: radial-gradient(
//       circle at center,
//       #d7f033 var(--bg-dot-size),
//       transparent var(--bg-dot-size)
//     );
//     background-size: var(--gap) var(--gap);
//     position: absolute;
//     mask: var(--mask);
//     // -webkit-mask-image: (--mask);
//     background-position: bottom right;
//     mask-image: var(--mask);
//     mask-size: cover;
//     mask-position: 200% 200%;
//     mask-repeat: no-repeat;
//     transition-duration: 0.15s;
//     transition-timing-function: ease, ease-in-out;
//     transition-property: top left;
//     // mask-size: 100% 100%;
//   }

//   &:first-child {
//     --mask: linear-gradient(230deg, transparent 60%, rgb(0 0 0 / 60%));

//     &:after {
//       left: calc(-1 * var(--offset));
//     }
//   }

//   &:last-child {
//     --mask: linear-gradient(130deg, transparent 60%, rgb(0 0 0 / 60%));

//     &:after {
//       left: var(--offset);
//     }
//   }

//   .card-header {
//     // display: flex;
//     // place-items: center;
//     &__title {
//       text-align: center;
//       // for font-align
//       margin: -0.1em 0;
//       font-size: 4em;
//       line-height: 0.71em;
//       position: absolute;
//       text-indent: -0.1em;
//       top: 0;
//       left: 0;
//       width: 100%;
//       // letter-spacing: 0.077em;
//       text-transform: uppercase;
//     }
//   }
// }
