@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter.var.woff2")
      format("woff2 supports variations"),
    url("../assets/fonts/Inter.var.woff2") format("woff2-variations");
  font-weight: 1 999;
  font-stretch: 75% 100%;
  font-style: oblique 0deg 20deg;
  font-display: swap;
}

@font-face {
  font-family: "InterSubset"; // Update the font family name to reflect the subset
  src: url("../assets/fonts/InterSubset.woff2") format("woff2"); // Point to the subset font file
  font-weight: 1 999; // Keep the same weight range
  font-stretch: 75% 100%; // Keep the same stretch range
  font-style: oblique 0deg 20deg; // Keep the same style
  font-display: swap; // Keep the same display property
}