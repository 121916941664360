*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizeSpeed;
  min-height: 100vh;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

@font-face {
  font-family: Inter;
  src: url("Inter.var.5f2f8893.woff2") format("woff2 supports variations"), url("Inter.var.5f2f8893.woff2") format("woff2-variations");
  font-weight: 1 999;
  font-stretch: 75% 100%;
  font-style: oblique 0deg 20deg;
  font-display: swap;
}

@font-face {
  font-family: InterSubset;
  src: url("InterSubset.c1008581.woff2") format("woff2");
  font-weight: 1 999;
  font-stretch: 75% 100%;
  font-style: oblique 0deg 20deg;
  font-display: swap;
}

.page-layout {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  flex-direction: column;
  gap: 2rem;
  max-width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden scroll;
}

.section {
  flex-direction: column;
  flex: 0 0 100vh;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 1em;
  display: flex;
}

.snap-start {
  scroll-snap-align: start;
}

.snap-center {
  scroll-snap-align: center;
}

.snap-end {
  scroll-snap-align: end;
}

.header {
  color: #fff;
  text-align: center;
  width: 100%;
  max-width: 36rem;
  min-height: 5em;
  margin-bottom: 1em;
  font-size: 1.8em;
  font-weight: 300;
  text-decoration: none;
}

.header > * {
  color: #fff;
  text-align: center;
  font-size: 1.8em;
  font-weight: 300;
  text-decoration: none;
}

.header > :hover .expand {
  color: #fff;
  font-weight: 500;
}

.reader {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20em;
  min-height: 20em;
  padding: 0 1.5em;
  display: flex;
  position: relative;
}

.reader .mark {
  content: "";
  pointer-events: none;
  width: 100%;
  height: calc(50% + 50vh);
  position: absolute;
  bottom: 0;
}

.reader p {
  opacity: .5;
}

.reader a {
  color: #fff;
  max-width: 36rem;
  font-size: 1.8em;
  text-decoration: none;
  transition: color .5s cubic-bezier(.38, .01, .23, 1.01);
}

@media (width <= 30em) {
  .reader a {
    font-size: 1.4em;
  }
}

.action-icons-card {
  border: 1px solid;
  display: flex;
}

@media (width <= 30em) {
  .action-icons-card {
    flex-direction: column;
  }
}

.action-icons-card__link-item {
  --item-size: calc(36rem / 3);
  color: #fff;
  justify-content: center;
  align-items: center;
  transition: background-color .5s cubic-bezier(.38, .01, .23, 1.01);
  display: flex;
}

@media (width <= 36rem) {
  .action-icons-card__link-item {
    --item-size: calc(24rem / 3);
  }
}

.action-icons-card__link-item {
  flex: 0 0 var(--item-size);
  width: var(--item-size);
  height: var(--item-size);
}

.action-icons-card__link-item .vector-icon {
  width: 3em;
}

.action-icons-card__link-item:hover {
  background-color: #ffffff1a;
}

:root {
  --shadow-color: 152deg 36% 56%;
  --shadow-elevation-low: .3px .5px .7px hsl(var(--shadow-color) / .34), .4px .8px 1px -1.2px hsl(var(--shadow-color) / .34), 1px 2px 2.5px -2.5px hsl(var(--shadow-color) / .34);
  --shadow-elevation-medium: .3px .5px .7px hsl(var(--shadow-color) / .36), .8px 1.6px 2px -.8px hsl(var(--shadow-color) / .36), 2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / .36), 5px 10px 12.6px -2.5px hsl(var(--shadow-color) / .36);
  --shadow-elevation-high: .3px .5px .7px hsl(var(--shadow-color) / .34), 1.5px 2.9px 3.7px -.4px hsl(var(--shadow-color) / .34), 2.7px 5.4px 6.8px -.7px hsl(var(--shadow-color) / .34), 4.5px 8.9px 11.2px -1.1px hsl(var(--shadow-color) / .34), 7.1px 14.3px 18px -1.4px hsl(var(--shadow-color) / .34), 11.2px 22.3px 28.1px -1.8px hsl(var(--shadow-color) / .34), 17px 33.9px 42.7px -2.1px hsl(var(--shadow-color) / .34), 25px 50px 62.9px -2.5px hsl(var(--shadow-color) / .34);
  --font-fallback: BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-sans;
  --font-body: InterSubset, var(--font-fallback);
  --font-heading: InterSubset, var(--font-fallback);
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  --accent-primary: $ accent-color;
}

::selection {
  color: #000;
  background: var(--accent-primary);
}

body {
  font-family: var(--font-body);
  color: #fff;
  accent-color: var(--accent-primary);
  background-color: #17171c;
}

.blob {
  pointer-events: none;
  opacity: .3;
  width: 100%;
  height: 100%;
  padding: 50px 0;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.blob .f {
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=index.ca544d6e.css.map */
